<template>
  <div>
    <div class="account-security-bg">
      <div class="account-security-con">
        <h2 class="page-title">账户安全</h2>
        <div class="account-set-list">
          <ul>
            <li>
              <div class="item-title">
                <i class="success"></i>
                <span>登录密码</span>
              </div>
              <div class="item-des">互联网账号存在被盗风险，建议您定期更改密码以保护账号安全。</div>
              <div class="item-operation">
                <span @click="openDialog(0)">修改</span>
              </div>
            </li>
            <li>
              <div class="item-title">
                <i class="error"></i>
                <span>绑定手机</span>
              </div>
              <div class="item-des">您验证的手机：13751081328若已丢失或停用，请立即更换，避免账户被盗。</div>
              <div class="item-operation">
                <span class="botton" @click="openDialog(1)">立即绑定</span>
              </div>
            </li>
            <li>
              <div class="item-title">
                <i class="success"></i>
                <span>绑定微信</span>
              </div>
              <!-- <div class="item-des">您已绑定微信，可直接使用微信快捷登录名欧国际官网。</div> -->
              <div class="item-des">绑定微信，可直接使用微信快捷登录名欧国际官网。</div>
              
              <div class="item-operation">
                <span @click="openDialog(2)">绑定</span>
              </div>
            </li>
            <li>
              <div class="item-title">
                <i class="doubt"></i>
                <span>紧急冻结账号</span>
              </div>
              <div class="item-des">紧急冻结账号后，账号无法正常使用，确保紧急情况下账号的安全。如需解冻请拨打客服电话400-6122-916进行申诉。</div>
              <div class="item-operation">
                <span class="botton" @click="openDialog(3)">紧急冻结</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog
      :title="activeDialogTitle"
      :visible.sync="dialogVisible"
      width="30%"
      center>
      <div class="change-password" v-if="activeIndex == 0">
        <el-form :model="form" :rules="rules" label-width="90px">
          <el-form-item label="手机号：" prop="userPhone">
            <el-input v-model="form.userPhone"></el-input>
          </el-form-item>
          <div class="verify">
            <el-form-item label="验证码：" prop="code">
              <el-input v-model="form.code"></el-input>
            </el-form-item>
            <div class="getCode">
              <el-button type="warning" :disabled="btnDisabled" @click="getCode">{{btnTip}}</el-button>
            </div>
          </div>
          <el-form-item label="设置密码：" prop="password">
            <el-input type="password" v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="confirmPassword">
            <el-input type="password" v-model="form.confirmPassword"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="bind-phone" v-else-if="activeIndex == 1">
        绑定手机号，玩命开发中，敬请期待！
      </div>
      <div class="unbind-wechat" v-else-if="activeIndex == 2">
        <iframe sandbox="allow-scripts allow-top-navigation" scrolling="no" width="300" height="400" frameBorder="0" allowTransparency="true" :src="setSrc"></iframe>
      </div>
      <div class="account-freeze" v-else-if="activeIndex == 3">
        账户冻结，玩命开发中，敬请期待！
      </div>
      <span slot="footer" class="dialog-footer" v-if="activeIndex != 2">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="warning" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import regExp from "@/util/regExp";
  export default {
    data (){
      return {
        token: null,
        dialogVisible: false,
        form: {
          userPhone: "",
          code: "",
          password: "",
          confirmPassword: ""
        },
        activeIndex: 0,
        activeDialogTitle: "修改密码",
        rules: {
          userPhone: [
            { validator: this.validateUserPhone, trigger: 'blur'}
          ],
          code: [
            { validator: this.validateCode, trigger: 'blur'}
          ],
          password: [
            { validator: this.validatePassword, trigger: 'blur'}
          ],
          confirmPassword: [
            { validator: this.validateConfirmPassword, trigger: 'blur'}
          ]
        },
        validatorResult: {
          userPhone: false,
          code: false,
          password: false,
          confirmPassword: false
        },
        countDownTime: 60,
        timer: null,
        btnTip: "获取验证码",
        btnDisabled: false,
        setSrc: "",
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
      },
      // 打开修改窗口
      openDialog(val){
        switch(val){
          case 0: this.activeDialogTitle = "修改密码";
          break;
          case 1: this.activeDialogTitle = "绑定手机";
          break;
          case 2: this.activeDialogTitle = "绑定微信"; this.loginErcode();
          break;
          case 3: this.activeDialogTitle = "紧急冻结账号";
          break;
        }
        this.activeIndex = val;
        this.dialogVisible = !this.dialogVisible;
      },
      // 获取验证码
      getCode(){
        if(!this.validatorResult.userPhone) return this.$message.error('请输入您的手机号');
        this.$api.getPhoneCode({
          phone: this.form.userPhone
        }).then(res => {
          if(res.status == 100){
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.btnDisabled = true;
            this.timer = setInterval(() => {
              this.countDown();
            },1000)
          }else{
            this.$message.error(res.msg);
          }
        }).catch(err => console.error(err));
      },
      // 验证用户手机号
      validateUserPhone(){
        if (!arguments[1].trim()){
          this.validatorResult.userPhone = false;
          return arguments[2](new Error('手机号不能为空'));
        }
        if(!regExp.phone.test(arguments[1].trim())){
          this.validatorResult.userPhone = false;
          return arguments[2](new Error('请输入正确的手机号'));
        } 
        this.validatorResult.userPhone = true;
      },
      // 验证验证码
      validateCode(){
        if (!arguments[1].trim()){
          this.validatorResult.code = false;
          return arguments[2](new Error('验证码不能为空'));
        }
        this.validatorResult.code = true;
      },
      // 验证用户是否输入密码
      validatePassword(){
        if (!arguments[1].trim()){
          this.validatorResult.password = false;
          return arguments[2](new Error('密码不能为空'));
        }
        this.validatorResult.password = true;
      },
      // 验证用户再次输入密码
      validateConfirmPassword(){
        if (!arguments[1].trim()){
          this.validatorResult.confirmPassword = false;
          return arguments[2](new Error('确认密码不能为空'));
        }
        if(arguments[1].trim() != this.form.password){
          this.validatorResult.confirmPassword = false;
          return arguments[2](new Error('两次输入的密码不一致，请重新输入。'));
        }
        this.validatorResult.confirmPassword = true;
      },
      // 提交修改
      submit(){
        if(!(this.validatorResult.userPhone && this.validatorResult.code && this.validatorResult.password && this.validatorResult.confirmPassword)) return this.$message.error('请录入完整的信息再提交');
        this.$api.changePassword({
          vcode: this.form.code,
          password: this.form.password,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.dialogVisible = !this.dialogVisible;
            clearInterval(this.timer);
            this.btnDisabled = false;
            this.countDownTime = 60;
            this.btnTip = "获取验证码";
            this.form.userPhone = this.form.code = this.form.password = this.form.confirmPassword = "";
            this.$message({
              message: '密码修改成功',
              type: 'success'
            });
          }else{
            this.$message.error(res.msg);
          }
        }).catch(err => console.error(err));
      },
      // 显示60s倒计时 
      countDown(){
        if(this.countDownTime < 0){
          clearInterval(this.timer);
          this.btnDisabled = false;
          this.countDownTime = 60;
          this.btnTip = "获取验证码";
        }else{
          this.btnTip = `${this.countDownTime}s`;
          this.countDownTime --;
        }
      },
      // 生成微信登录二维码 
      loginErcode(){
        this.$api.loginErcode({type: 2}).then(res => {
          this.setSrc = res + "&href=https://e-home.oss-cn-shenzhen.aliyuncs.com/weCodeStyle.css";
        }).catch(err => console.error(err))
      },
      // 绑定微信
      wechatBind(){
        
      },
      // 打开修改密码窗口
      openChangePassword(){
        
      },
      // 打开绑定手机号窗口
      openBindPhone(){
        
      },
      // 打开解绑微信窗口
      openUnbindWeChat(){
        // this.loginErcode();
      },
      // 打开账户冻结窗口
      openAccountFreeze(){
        
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/accountSecurity/accountSecurity";
</style>
